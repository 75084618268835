import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { MapLogo } from './components/MapLogo';
import { LocationControl } from './components/LocationControl';
import { BottomBar } from './components/BottomBar';
import { LocationMarker } from './components/LocationMarker';
import { Icon } from 'leaflet';

// Fix Leaflet default icon path issues
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = new Icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [0, -41]
});
Icon.Default.prototype.options.iconUrl = icon;
Icon.Default.prototype.options.shadowUrl = iconShadow;

interface PathwayFeature {
  properties: {
    access_hours: string;
    name?: string;
    description?: string;
    structure_type?: string;
  };
}

function App() {
  const [pathways, setPathways] = useState<any>(null);
  const [locationActive, setLocationActive] = useState(false);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [userLocation, setUserLocation] = useState<[number, number] | null>(null);

  useEffect(() => {
    fetch('https://data.calgary.ca/resource/3u3x-hrc7.geojson')
      .then(response => response.json())
      .then(data => {
        // Filter out test bridges and removed features
        const filteredData = {
          ...data,
          features: data.features.filter((feature: any) => {
            const name = feature.properties.name?.toLowerCase() || '';
            const description = feature.properties.description?.toLowerCase() || '';
            const accessHours = feature.properties.access_hours?.toLowerCase() || '';
            return !name.includes('test') && 
                   !name.includes('removed') && 
                   !description.includes('test') && 
                   !description.includes('removed') &&
                   !accessHours.includes('test') &&
                   !accessHours.includes('removed');
          })
        };
        setPathways(filteredData);
      })
      .catch(error => console.error('Error fetching Plus 15 data:', error));
  }, []);

  const isCurrentlyAccessible = (accessHours: string | null | undefined): boolean => {
    if (!accessHours || accessHours.toLowerCase().includes('closed')) {
      return false;
    }
    
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const currentTime = currentHour * 60 + currentMinute;
    
    try {
      // Handle "Daily" and "Monday - Friday" schedules
      const isDailySchedule = accessHours.toLowerCase().includes('daily');
      const isWeekdaySchedule = accessHours.toLowerCase().includes('monday - friday');
      const currentDay = now.getDay();
      const isWeekday = currentDay >= 1 && currentDay <= 5;

      // Check if current day matches schedule
      if (!isDailySchedule && isWeekdaySchedule && !isWeekday) {
        return false;
      }

      // Extract time range
      let timeRange = accessHours.toLowerCase();
      if (isDailySchedule) {
        timeRange = timeRange.replace('daily,', '').trim();
      } else if (isWeekdaySchedule) {
        timeRange = timeRange.replace('monday - friday,', '').trim();
      }

      // Parse start and end times
      const [startStr, endStr] = timeRange.split('-').map(t => t.trim());
      
      // Convert start time to minutes
      const startMatch = startStr.match(/(\d+)(?::(\d+))?\s*(am|pm)/i);
      if (!startMatch) return false;
      
      let startHour = parseInt(startMatch[1]);
      const startMinute = startMatch[2] ? parseInt(startMatch[2]) : 0;
      if (startMatch[3].toLowerCase() === 'pm' && startHour !== 12) startHour += 12;
      if (startMatch[3].toLowerCase() === 'am' && startHour === 12) startHour = 0;
      const startTime = startHour * 60 + startMinute;

      // Convert end time to minutes
      let endTime;
      if (endStr.toLowerCase().includes('midnight')) {
        endTime = 24 * 60;
      } else {
        const endMatch = endStr.match(/(\d+)(?::(\d+))?\s*(am|pm)/i);
        if (!endMatch) return false;
        
        let endHour = parseInt(endMatch[1]);
        const endMinute = endMatch[2] ? parseInt(endMatch[2]) : 0;
        if (endMatch[3].toLowerCase() === 'pm' && endHour !== 12) endHour += 12;
        if (endMatch[3].toLowerCase() === 'am' && endHour === 12) endHour = 0;
        endTime = endHour * 60 + endMinute;
      }

      return currentTime >= startTime && currentTime <= endTime;
    } catch (error) {
      console.error('Error parsing hours:', error);
      return false;
    }
  };

  const baseStyle = {
    weight: 2,
    opacity: 1,
    fillOpacity: 0.5
  };

  const style = (feature: PathwayFeature) => {
    if (!feature.properties.access_hours || 
        feature.properties.access_hours === '<Null>' || 
        feature.properties.access_hours.toLowerCase() === 'unavailable') {
      return {
        ...baseStyle,
        color: '#808080'
      };
    }

    const isOpen = isCurrentlyAccessible(feature.properties.access_hours);
    
    return {
      ...baseStyle,
      color: isOpen ? '#2ecc71' : '#e74c3c'
    };
  };

  const onEachFeature = (feature: PathwayFeature, layer: any) => {
    if (feature.properties) {
      const hours = feature.properties.access_hours;
      const isOpen = isCurrentlyAccessible(hours);
      const status = !hours || hours === '<Null>' || hours.toLowerCase() === 'unavailable' 
        ? 'Unknown' 
        : isOpen ? 'Open' : 'Closed';

      const displayHours = !hours || hours === '<Null>' || hours.toLowerCase() === 'unavailable'
        ? 'Unavailable'
        : hours;
      
      layer.bindPopup(`
        <div class="p-4 min-w-[280px]">
          ${feature.properties.name ? 
            `<h3 class="font-bold mb-3 text-lg border-b pb-2">${feature.properties.name}</h3>` 
            : ''
          }
          
          <div class="space-y-4">
            <div class="flex items-start gap-3">
              <div class="mt-1">
                <div class="w-5 h-5 flex items-center justify-center">
                  ${status === 'Open' ? 
                    '<span class="w-3 h-3 rounded-full bg-green-500"></span>' :
                    status === 'Closed' ? 
                    '<span class="w-3 h-3 rounded-full bg-red-500"></span>' :
                    '<span class="w-3 h-3 rounded-full bg-gray-500"></span>'
                  }
                </div>
              </div>
              <div>
                <div class="font-medium">Status</div>
                <div class="text-sm text-gray-600">${status}</div>
              </div>
            </div>

            <div class="flex items-start gap-3">
              <div class="mt-1">
                <svg class="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </div>
              <div>
                <div class="font-medium">Access Hours</div>
                <div class="text-sm text-gray-600">${displayHours}</div>
              </div>
            </div>

            ${feature.properties.structure_type ? `
              <div class="flex items-start gap-3">
                <div class="mt-1">
                  <svg class="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"></path>
                  </svg>
                </div>
                <div>
                  <div class="font-medium">Structure Type</div>
                  <div class="text-sm text-gray-600">${feature.properties.structure_type}</div>
                </div>
              </div>
            ` : ''}

            ${feature.properties.description ? `
              <div class="flex items-start gap-3">
                <div class="mt-1">
                  <svg class="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </div>
                <div>
                  <div class="font-medium">Additional Info</div>
                  <div class="text-sm text-gray-600">${feature.properties.description}</div>
                </div>
              </div>
            ` : ''}
          </div>
        </div>
      `);
    }
  };

  const handleLocationChange = (position: [number, number] | null) => {
    setUserLocation(position);
    if (position) {
      setLocationError(null);
    }
  };

  const handleLocationError = (error: string) => {
    setLocationError(error);
    setLocationActive(false);
    setUserLocation(null);
  };

  const handleRequestLocation = () => {
    setLocationError(null);
    setLocationActive(true);
  };

  return (
    <>
      <div className="h-screen w-full">
        <MapContainer
          center={[51.0480, -114.0690]}
          zoom={16}
          style={{ width: '100%', height: '100%' }}
          minZoom={14}
          maxZoom={18}
          zoomControl={false}
          dragging={true}
          touchZoom={true}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
          />
          {pathways && (
            <GeoJSON
              data={pathways}
              style={style}
              onEachFeature={onEachFeature}
            />
          )}
          {locationActive && (
            <LocationMarker
              onLocationChange={handleLocationChange}
              onError={handleLocationError}
            />
          )}
          <MapLogo />
        </MapContainer>
        <LocationControl 
          onRequestLocation={handleRequestLocation}
          isActive={locationActive}
          error={locationError}
        />
        <BottomBar />
      </div>
    </>
  );
}

export default App;