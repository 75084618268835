import { Download, ExternalLink } from 'lucide-react';
import { FactRotator } from './FactRotator';

export function BottomBar() {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-black text-white py-6 md:py-7 px-4 shadow-lg z-[1000]">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between gap-6">
        <div className="flex-1 text-center md:text-left">
          <h1 className="text-2xl font-bold mb-2">Calgary Plus 15 Map</h1>
          <FactRotator />
        </div>
        <div className="flex flex-col items-center gap-4">
          <a
            href="https://apps.apple.com/ca/app/15-calgary-pathway-map/id425298151"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 px-4 py-2 bg-white text-black rounded-full hover:bg-gray-200 transition-colors"
          >
            <Download size={20} />
            <span>Download iOS App</span>
          </a>
          <a
            href="https://mediumrare.co"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-white hover:text-gray-300 transition-colors text-s"
          >
            <span>Made by mediumrare</span>
            <ExternalLink size={14} />
          </a>
        </div>
      </div>
    </div>
  );
}