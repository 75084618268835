import { useEffect, useState } from 'react';

const facts = [
  "Calgary's +15 is the largest skyway network, spanning over 18km",
  "The system was conceived by architect Harold Hanen in the late 1960s",
  "Named '+15' because walkways are approximately 15 feet above street level",
  "The network connects over 100 buildings across downtown Calgary",
  "Serves over 100,000 pedestrians daily during peak business hours",
  "The first bridge was opened in 1970, connecting Calgary Place to the Calgary Inn",
  "By 1984, Calgary's +15 network had already grown to include 38 bridges",
  "The network includes art galleries, retail spaces, and food courts",
  "Some +15 bridges span up to 40 meters (131 feet) across city streets",
  "The system has inspired similar networks in other cold-weather cities worldwide",
  "Many buildings in Calgary were specifically designed to integrate with the +15"
];

export function FactRotator() {
  const [currentFact, setCurrentFact] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentFact((prev) => (prev + 1) % facts.length);
        setIsAnimating(false);
      }, 500);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="hidden md:block h-6 relative overflow-hidden">
      <p
        className={`
          text-gray-300 text-sm italic absolute w-full
          transition-all duration-1000 ease-in-out
          ${isAnimating ? 'translate-y-8 opacity-0' : 'translate-y-0 opacity-100'}
        `}
      >
        {facts[currentFact]}
      </p>
    </div>
  );
}