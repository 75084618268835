import React, { useState } from 'react';
import { X, Check, Sparkles } from 'lucide-react';

interface AdvertiseDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export function AdvertiseDrawer({ isOpen, onClose }: AdvertiseDrawerProps) {
  const [selectedPlan, setSelectedPlan] = useState<'monthly' | 'annual'>('monthly');

  const handlePurchase = () => {
    const stripeUrl = selectedPlan === 'monthly' 
      ? 'https://buy.stripe.com/eVa4gr83AbXDb0kbIK'
      : 'https://buy.stripe.com/6oEfZ997E4vb0lG288';
    window.open(stripeUrl, '_blank');
  };

  return (
    <>
      {/* Backdrop */}
      <div 
        className={`fixed inset-0 bg-black/30 backdrop-blur-sm transition-opacity z-[1001] ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={onClose}
      />

      {/* Drawer */}
      <div 
        className={`fixed right-0 top-0 h-full w-full max-w-md bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-[1002] ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="p-6 h-full flex flex-col">
          {/* Header */}
          <div className="flex flex-col mb-6">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl font-bold text-gray-900">Advertise Your Business</h2>
              <button 
                onClick={onClose}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="w-5 h-5 text-gray-500" />
              </button>
            </div>
            <p className="text-gray-600 mt-2">Reach thousands of downtown professionals daily through Calgary's iconic +15 network.</p>
          </div>

          {/* Content */}
          <div className="flex-1 space-y-6">
            <div className="inline-flex items-center gap-2 px-3 py-1.5 bg-blue-50 text-blue-600 rounded-full">
              <Sparkles size={16} className="animate-pulse" />
              <span className="text-sm font-medium">Introductory Pricing</span>
            </div>

            <div className="space-y-4">
              <h4 className="font-medium text-gray-900">Every listing includes:</h4>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-start gap-2">
                  <Check size={18} className="text-blue-500 mt-0.5 flex-shrink-0" />
                  <span>Map placement with custom pin</span>
                </li>
                <li className="flex items-start gap-2">
                  <Check size={18} className="text-blue-500 mt-0.5 flex-shrink-0" />
                  <span>Direct website link</span>
                </li>
                <li className="flex items-start gap-2">
                  <Check size={18} className="text-blue-500 mt-0.5 flex-shrink-0" />
                  <span>Phone Number</span>
                </li>
              </ul>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => setSelectedPlan('monthly')}
                className={`text-left p-4 rounded-lg transition-all ${
                  selectedPlan === 'monthly'
                    ? 'bg-blue-50 border-2 border-blue-500 shadow-sm'
                    : 'bg-gray-50 hover:bg-gray-100 border-2 border-transparent'
                }`}
              >
                <div className="text-center space-y-1">
                  <div>
                    <span className="text-2xl font-bold text-gray-900">$20</span>
                    <span className="text-gray-600">/mo</span>
                  </div>
                  <p className="text-sm text-gray-500">Monthly plan</p>
                </div>
              </button>
              <button
                onClick={() => setSelectedPlan('annual')}
                className={`text-left p-4 rounded-lg transition-all ${
                  selectedPlan === 'annual'
                    ? 'bg-blue-50 border-2 border-blue-500 shadow-sm'
                    : 'bg-gray-50 hover:bg-gray-100 border-2 border-transparent'
                }`}
              >
                <div className="text-center space-y-1">
                  <div>
                    <span className="text-2xl font-bold text-gray-900">$10</span>
                    <span className="text-gray-600">/mo</span>
                  </div>
                  <p className="text-sm text-gray-500">Annual plan</p>
                  <p className="text-xs text-blue-600 font-medium">Save 50% (paid upfront)</p>
                </div>
              </button>
            </div>

            <button
              onClick={handlePurchase}
              className="flex items-center justify-center gap-2 w-full bg-blue-500 text-white py-3 px-4 rounded-lg hover:bg-blue-600 transition-colors"
            >
              <span>Get Started Today</span>
            </button>

            <div className="text-center">
              <a
                href="mailto:bryan@mediumrare.co?subject=Advertising on +15"
                className="text-blue-500 hover:text-blue-600 text-sm"
              >
                Questions? Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}