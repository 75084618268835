import React, { useEffect, useState } from 'react';
import { Circle, CircleMarker, useMap } from 'react-leaflet';
import { LatLng } from 'leaflet';

interface LocationMarkerProps {
  onLocationChange: (position: [number, number] | null) => void;
  onError: (error: string) => void;
}

export function LocationMarker({ onLocationChange, onError }: LocationMarkerProps) {
  const [position, setPosition] = useState<[number, number] | null>(null);
  const [accuracy, setAccuracy] = useState<number>(0);
  const map = useMap();

  useEffect(() => {
    const onLocationFound = (e: { latlng: LatLng; accuracy: number }) => {
      const newPosition: [number, number] = [e.latlng.lat, e.latlng.lng];
      setPosition(newPosition);
      setAccuracy(e.accuracy);
      onLocationChange(newPosition);
      
      // Only set view on first location found
      if (!position) {
        map.setView(newPosition, 17, {
          animate: true,
          duration: 1
        });
      }
    };

    const onLocationError = (e: { message: string }) => {
      onError(e.message);
      onLocationChange(null);
    };

    const startLocate = () => {
      map.locate({
        watch: true,
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      });
    };

    map.on('locationfound', onLocationFound);
    map.on('locationerror', onLocationError);
    startLocate();

    return () => {
      map.stopLocate();
      map.off('locationfound', onLocationFound);
      map.off('locationerror', onLocationError);
    };
  }, [map, onLocationChange, onError]);

  return position === null ? null : (
    <>
      <Circle 
        center={position} 
        radius={accuracy} 
        pathOptions={{
          color: '#1b75bb',
          fillColor: '#1b75bb',
          fillOpacity: 0.15,
          weight: 2,
          opacity: 0.5
        }}
      />
      <CircleMarker 
        center={position}
        radius={6}
        pathOptions={{
          color: '#1b75bb',
          fillColor: '#1b75bb',
          fillOpacity: 1,
          weight: 2,
          opacity: 1
        }}
      />
    </>
  );
}