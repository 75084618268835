import React, { useState } from 'react';
import { Navigation2, Building2 } from 'lucide-react';
import { AdvertiseDrawer } from './AdvertiseDrawer';

interface LocationControlProps {
  onRequestLocation: () => void;
  isActive: boolean;
  error: string | null;
}

export function LocationControl({ onRequestLocation, isActive, error }: LocationControlProps) {
  const [showError, setShowError] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <div className="fixed top-4 md:top-8 right-4 md:right-8 z-[1000] flex flex-col items-end gap-2">
        {showError && error && (
          <div className="bg-white rounded-lg shadow-lg p-3 mb-2 text-sm max-w-[200px] text-gray-700">
            {error === 'User denied Geolocation' 
              ? 'Please enable location services to see your position on the map.'
              : 'Unable to get your location. Please try again.'}
          </div>
        )}
        <div className="flex gap-2">
          <button
            onClick={() => setIsDrawerOpen(true)}
            className="flex items-center gap-2 px-4 py-3 rounded-full shadow-lg bg-white text-gray-700 hover:bg-gray-100 transition-all group"
            title="Advertise with us"
          >
            <span className="font-medium">Add Your Business</span>
            <Building2 size={24} className="group-hover:scale-110 transition-transform" />
          </button>
          <button
            onClick={() => {
              onRequestLocation();
              setShowError(!!error);
            }}
            className={`p-3 rounded-full shadow-lg transition-all ${
              isActive 
                ? 'bg-blue-500 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-100'
            }`}
            title={isActive ? 'Location tracking active' : 'Show my location'}
          >
            <Navigation2 
              size={24} 
              className={isActive ? 'animate-pulse' : ''} 
            />
          </button>
        </div>
      </div>
      <AdvertiseDrawer 
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
}